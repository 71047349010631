import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import ConfigTable01 from "components/Internet_Access/Port_Forwarding/Zyxel_Speedlink/ConfigTable01";
import DangerBox from 'components/Internet_Access/Port_Forwarding/Unitymedia_Connectbox/DangerBox';
import NavButtons from 'components/Internet_Access/Port_Forwarding/NavButtons';
import ForumBox from 'components/Internet_Access/Port_Forwarding/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Port Weiterleitung - Unitymedia Connectbox",
  "path": "/Internet_Access/Port_Forwarding/Unitymedia_Connectbox/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Connect to your Camera through the Internet",
  "image": "IA_SearchThumb_Portforwarding_Pirelli.png",
  "social": "/images/Search/IA_SearchThumb_Portforwarding_Pirelli.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-Port-Forwarding_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Port Weiterleitung - Unitymedia Connectboxk' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Connect to your Camera through the Internet' image='/images/Search/IA_SearchThumb_Portforwarding_Pirelli.png' twitter='/images/Search/IA_SearchThumb_Portforwarding_Pirelli.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Internet_Access/Port_Forwarding/Unitymedia_Connectbox/' locationFR='/fr/Internet_Access/Port_Forwarding/Unitymedia_Connectbox/' crumbLabel="Unitymedia Connectbox" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "port-forwarding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#port-forwarding",
        "aria-label": "port forwarding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Port Forwarding`}</h1>
    <h2 {...{
      "id": "unitymedia-connectbox",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#unitymedia-connectbox",
        "aria-label": "unitymedia connectbox permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unitymedia Connectbox`}</h2>
    <p>{`This is the 2nd step of the port forwarding, click `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`HERE`}</a>{` to get to the beginning of the instructions.`}</p>
    <p>{`Open the web interface of your Unitymedia Connect Box by entering the IP address of the router (192.168.0.1 by default) in the address bar of your browser.`}</p>
    <p>{`In the menu, click on `}<strong parentName="p">{`Advanced Settings → Security → Port Forwarding`}</strong>{`. Here you will see the list of all port forwarders. Click on `}<strong parentName="p">{`Create a new rule`}</strong>{` to enter the port forwarding for the camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/022f8bbe9606871fd1ec0ea336305092/82b28/unitymedia_connectbox_forwarding_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABLElEQVQoz02QzU5EIQyF73uamLh068aYuHLjI6kbfRYz/txkMlygUKC0xQCZ65wFEOBrz+kSIGCMqQtrre0s1b7m7/Xu+ePq/uXm8e364fX26T1l3l+XeiERaSKVKxHNG0L8Wt3njz2s7vDrDqsr44moMvMSQogx5pwSYspZrQ3OnYzx3jvnnPcheO9tTjFGwAhuyFqbUlpUlYiYWVV759YQ0ZgTAFjbP3kAYwwAbNvmwW+bAQjbtuWclx6MJQHyus6sIjLLzYrMLENjZ+Iy26hqh4koIbZc5qhEpJQizLVWZuFx6MCAy4CZ+9g6XKlizoWqFvqHReYIu5EdVi6cVBvLGU6FPOYIUY/HCU+sGx4O94mISp2dd7jbq9yaXtgemc8lZuyx9czdwCj3B6noB8gShH6wAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/022f8bbe9606871fd1ec0ea336305092/e4706/unitymedia_connectbox_forwarding_01.avif 230w", "/en/static/022f8bbe9606871fd1ec0ea336305092/d1af7/unitymedia_connectbox_forwarding_01.avif 460w", "/en/static/022f8bbe9606871fd1ec0ea336305092/7f308/unitymedia_connectbox_forwarding_01.avif 920w", "/en/static/022f8bbe9606871fd1ec0ea336305092/164ea/unitymedia_connectbox_forwarding_01.avif 931w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/022f8bbe9606871fd1ec0ea336305092/a0b58/unitymedia_connectbox_forwarding_01.webp 230w", "/en/static/022f8bbe9606871fd1ec0ea336305092/bc10c/unitymedia_connectbox_forwarding_01.webp 460w", "/en/static/022f8bbe9606871fd1ec0ea336305092/966d8/unitymedia_connectbox_forwarding_01.webp 920w", "/en/static/022f8bbe9606871fd1ec0ea336305092/c4004/unitymedia_connectbox_forwarding_01.webp 931w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/022f8bbe9606871fd1ec0ea336305092/81c8e/unitymedia_connectbox_forwarding_01.png 230w", "/en/static/022f8bbe9606871fd1ec0ea336305092/08a84/unitymedia_connectbox_forwarding_01.png 460w", "/en/static/022f8bbe9606871fd1ec0ea336305092/c0255/unitymedia_connectbox_forwarding_01.png 920w", "/en/static/022f8bbe9606871fd1ec0ea336305092/82b28/unitymedia_connectbox_forwarding_01.png 931w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/022f8bbe9606871fd1ec0ea336305092/c0255/unitymedia_connectbox_forwarding_01.png",
              "alt": "Portforwarding Unitymedia Connectbox",
              "title": "Portforwarding Unitymedia Connectbox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lokale IP`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter the IP address of the camera here. You can read out the address in the Instar Camera Tool or in the device list of the router.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lokaler Start Port`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter the port of your camera here, i.e. `}<strong parentName="td">{`8081`}</strong>{` for camera 1 as in step 1. If you add further cameras, then change the corresponding port for each of them (`}<strong parentName="td">{`8082`}</strong>{` for camera 2 etc.).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lokaler End Port`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter the same port here as in the previous field.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Externer Start Port`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter the same port here as in the previous field.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Externer End Port`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter the same port here as in the previous field.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Protokoll`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Choose here `}<strong parentName="td">{`TCP`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Aktiviert`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Choose here `}<strong parentName="td">{`An`}</strong></td>
        </tr>
      </tbody>
    </table>
    <p>{`Then confirm the entries and the port forwarding for the camera is active.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "518px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/63d7aef7456cc91a1b535867a8285a74/6b9fd/unitymedia_connectbox_forwarding_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxklEQVQoz42SQZaEQAhDvf91VUiA4Dyreny90favWFQqEFi6GwDJ7j4GJAFkprtHxHHPIgnA9yMO4qXYzNzdzHbzrCKwbVt3SzoeWbq7KusfSREBYNY/xJK2fb/MRwS+rqukqnrj/CEzVXU5/xZXppmB8AHOpLFt+6uZ55A5iAhJJN3slfNc1VyPOzKL5G72auYpBnA1GREkNegbznSkZVZkaFxYH8fV/wMfsarMjOT3hWXmyOuW+fvpPAO7xGd0lf1IVXX3H/lQAeTQq6dCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63d7aef7456cc91a1b535867a8285a74/e4706/unitymedia_connectbox_forwarding_02.avif 230w", "/en/static/63d7aef7456cc91a1b535867a8285a74/d1af7/unitymedia_connectbox_forwarding_02.avif 460w", "/en/static/63d7aef7456cc91a1b535867a8285a74/0d37b/unitymedia_connectbox_forwarding_02.avif 518w"],
              "sizes": "(max-width: 518px) 100vw, 518px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/63d7aef7456cc91a1b535867a8285a74/a0b58/unitymedia_connectbox_forwarding_02.webp 230w", "/en/static/63d7aef7456cc91a1b535867a8285a74/bc10c/unitymedia_connectbox_forwarding_02.webp 460w", "/en/static/63d7aef7456cc91a1b535867a8285a74/c29d2/unitymedia_connectbox_forwarding_02.webp 518w"],
              "sizes": "(max-width: 518px) 100vw, 518px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63d7aef7456cc91a1b535867a8285a74/81c8e/unitymedia_connectbox_forwarding_02.png 230w", "/en/static/63d7aef7456cc91a1b535867a8285a74/08a84/unitymedia_connectbox_forwarding_02.png 460w", "/en/static/63d7aef7456cc91a1b535867a8285a74/6b9fd/unitymedia_connectbox_forwarding_02.png 518w"],
              "sizes": "(max-width: 518px) 100vw, 518px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/63d7aef7456cc91a1b535867a8285a74/6b9fd/unitymedia_connectbox_forwarding_02.png",
              "alt": "Portforwarding Unitymedia Connectbox",
              "title": "Portforwarding Unitymedia Connectbox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In order to also be able to access the flash video, a second port forwarding must now be set up for your camera. To do this, follow the previous instructions exactly, except enter the RMTP port of your camera in the last window. For your first camera, enter 1935 in all fields, for your second camera 1936, and so on.`}</p>
    <p>{`The settings in the router are now complete and you can continue with the next step. To do this, click `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Mobile_Access/"
      }}>{`HERE`}</a>{`.`}</p>
    <h2 {...{
      "id": "step-iii-the-cameras-ddns-address-and-the-remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-iii-the-cameras-ddns-address-and-the-remote-access",
        "aria-label": "step iii the cameras ddns address and the remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step III: The Camera´s DDNS-Address and the Remote-Access`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Internet_Access/Mobile_Access/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Step III</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      